<template>
  <div class="product-wrap product-water">
    <Header is-active="product" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-water">
      <div class="txt"></div>
    </div>
    <div class="white-wrap">
      <div class="fz-big align-ct"><h4>智慧水务是什么</h4></div>
      <div class="txt-box align-ct">
        <p>智慧水务是基于物联网、大数据、云计算、人工智能等技术实现的智慧水务管理平台， </p>
        <p>通过新一代信息技术与水务技术的深度融合，充分发掘数据价值和逻辑关系，实现水务业务系统的控制智能化、数据资源化、</p>
        <p>管理精确化、决策智慧化，保障水务设施安全运行，使水务业务运营更高效、管理更科学和服务更优质。</p>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="fz-big align-ct"><h4>平台优势</h4></div>
      <div class="ys-pic">
        <img src="../../assets/img/product/ptys.png" alt="平台优势">
      </div>
    </div>
    <div class="white-wrap">
      <div class="fz-big align-ct"><h4>平台架构</h4></div>
      <div class="jg-pic">
        <img src="../../assets/img/product/water/jg.png" alt="平台架构">
      </div>
    </div>
    <div class="blue-wrap">
      <el-row class="max-center pd-5">
        <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
          <div class="bs-pic"></div>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
          <div class="bs-info">
            <div class="fz-big align-lt"><h4>平台部署</h4></div>
            <p class="align-lt">支持公有云、私有云和混合云（公有云+私有云）三种部署方式。</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="grey-wrap">
      <div class="fz-big align-ct"><h4>平台功能</h4></div>
      <div class="anchor-box">
        <div class="nav-btn"><a href="#scdd">生产调度</a></div>
        <div class="nav-btn"><a href="#zhsw">智慧水厂</a></div>
        <div class="nav-btn"><a href="#wrbz">无人泵站</a></div>
        <div class="nav-btn"><a href="#fqjl">分区计量</a></div>
        <div class="nav-btn"><a href="#gwxj">管网巡检</a></div>
        <div class="nav-btn"><a href="#jyxt">加药系统</a></div>
        <div class="nav-btn"><a href="#nhgl">能耗管理</a></div>
        <div class="nav-btn"><a href="#sbgl">设备管理</a></div>
        <div class="nav-btn"><a href="#app">APP</a></div>
        <div class="nav-btn"><a href="#dzhkf">定制化开发</a></div>
      </div>
      <div id="scdd"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic a-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>SCADA 生产调度</h5></div>
              <div class="txt-box">
                SCADA生产调度系统对水厂下属的取水泵站、自来水厂、加压泵站、供水管网等重要供用水单元纳入全方位的的监控和管理。系统实时采集流量、压力、电压、电流、液位、水质等各类前端监测数据，根据需要进行远程启停和调节，将供水生产监控、调度、数据分析和业务管理统一到一个综合性的系统平台。系统提供供水全过程生产运行数据采集存储、运行情况可视化展示、调度分析决策、业务过程管理、异常检测预警及运行能效分析和报表管理等功能，全面提升调度管理水平，为调度管理规范化、流程化、精细化、网络化和动态化提供技术支撑。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="zhsw"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>SWS 智慧水厂</h5></div>
              <div class="txt-box">
                <p>
                  智慧水厂以自动化设备、仪表为基础，以智能化前后终端和模块为辅，以水厂设备自动化控制和水厂工艺流程监测为中心，建立智能化、信息化、管制一体化的统一管理平台，将传统的自动化控制系统、大屏幕显示系统、闭路电视监控系统、报警系统、楼宇智能、设备管理、水质管理等本来就相辅相成的系统统一到这个统一管理平台，通过数据信息共享、交换，在发挥各系统原有功能同时协同运行工作，实现水厂各个环节集中监测控制和管理的“水厂智能化控制系统”为最终目标。</p>
                <p>
                  另外，水厂智能化控制系统还可以实现日常生产调度和管理的自动化，可以通过工作站自动统计日常生产报表、日常事件记录日志等文档材料，可以自动记录报警情况和生成历史数据库和历史曲线，为生产状况的分析提供方便。</p>
                <p>水厂智能管理系统主要包括：实时远程监控、运营管理中心、报警管理、设备管理、能耗监测、安防管理、应急指挥管理、指挥决策等功能模块。</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic b-pic" style="margin-top: 10%"></div>
          </el-col>
        </el-row>
      </div>
      <div id="wrbz"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic c-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>SPS 无人泵站</h5></div>
              <div class="txt-box">
                SCADA生产调度系统对水厂下属的取水泵站、自来水厂、加压泵站、供水管网等重要供用水单元纳入全方位的的监控和管理。系统实时采集流量、压力、电压、电流、液位、水质等各类前端监测数据，根据需要进行远程启停和调节，将供水生产监控、调度、数据分析和业务管理统一到一个综合性的系统平台。系统提供供水全过程生产运行数据采集存储、运行情况可视化展示、调度分析决策、业务过程管理、异常检测预警及运行能效分析和报表管理等功能，全面提升调度管理水平，为调度管理规范化、流程化、精细化、网络化和动态化提供技术支撑。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="fqjl"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>DMA 分区计量</h5></div>
              <div class="txt-box">
                <p>通过对各DMA(独立计量区域）内的流量和压力节点实施远程实时监测，既可及时发现管网供水异常，又可测算出区域的漏损情况、并辅助查找漏点，有效降低管网漏损率和产销差率。</p>
                <p>DMA分区计量系统包括：计量分区、区域压力分析、区域流量分析、压力监测、漏损监测、漏损查询统计、DMA管理内容、夜间最小流量分析、确定真实漏损率、确定表观漏损水量、计算各区域产销差等功能。</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic d-pic"></div>
          </el-col>
        </el-row>
      </div>
      <div id="gwxj"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic e-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>PIS 管网巡检</h5></div>
              <div class="txt-box">
                管网巡检系统主要用于实现水厂内部的管网巡检和管理系统过程的网格化、智能化。管网具备强大的巡线人员定位功能和历史轨迹查询功能，实时掌握人员位置信息，配备手持智能终端软件，能够快速查看隐患现场和施工现场的图片或视频信息，方便指挥人员对现场进行快速全面掌握。主要包括：巡检实时管理、定位管理（人员定位、车辆定位）、任务管理（巡检任务无线接收功能）、巡检配置管理、巡检案件管理、巡检绩效管理、巡检报表（日报、周报、月报）、巡检排班管理、案件统计分析、巡检记录管理、巡检上报管理（现场照片采集及无线上传功能）等功能。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="jyxt"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>PAC 加药系统</h5></div>
              <div class="txt-box">
                控制系统（PLC、变频调速器、触摸屏）、溶液搅拌、计量泵输送、物位仪表、流量仪表、分析仪表等组成。本系统针对特定过程参数（如PH、余氯等）按设定浓度点或浓度范围自动投加所需药品的一体化装置。可实现远程监控和控制投加量，动态参数调整等功能。
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic f-pic"></div>
          </el-col>
        </el-row>
      </div>
      <div id="nhgl"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic g-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>ECS 能耗管理</h5></div>
              <div class="txt-box">
                根据现有水厂泵站SCADA运行进行数据分析，初步确定目前水厂泵站的运行工况，根据初步分析结果，结合水厂泵站现场测试（包括管路和阀门的测试），掌握水厂泵站全工况数据，可以分析不同水泵组合调度和节能经济分析，对于偏离最优工况较大的水泵进行改造。同时能耗管理分析系统可以实时掌握水泵主要运行参数（如：流量、压力、功率、转速）以及定时记录辅助运行参数（如：轴温、噪音、振动、水锤等），根据水泵运行参数的变化进行水泵节能的分析和检修计划，并且利用运行数据可以评价调度员当班的能耗指标，提高调度员对节能降耗积极性。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="sbgl"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>EMS 设备管理</h5></div>
              <div class="txt-box">
                <p>
                  涵盖设备的整个生命周期，实现完善的设备管理体系，设备管理工作流程标准化、规范化、系统化，有效提高了信息传递实时性、准确性。设备故障维修的相关信息准确传递给各级管理人员，增加信息透明度，提高工作效率。强大的统计分析功能，实时、准确的生成各类统计数据，为管理决策提供可靠依据。系统采用模块化设计，可与我公司其他产品进行无缝连接，扩展应用功能。系统可通过华信数据采集平台，直接从SCADA系统中获取设备运行数据，提高数据可靠性，减轻操作人员工作量。 </p>
                <p>系统提供开放的数据接口，可为第三方软件提供可靠设备相关数据。 </p>
                <p> 设备管理主要包括设备台账、设备看板、设备巡检、设备维保等功能。 </p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic h-pic"></div>
          </el-col>
        </el-row>
      </div>
      <div id="app"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic i-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>APP</h5></div>
              <div class="txt-box">
                平台配套可提供移动端版本，包括WEB端大部分功能，满足您的移动办公需求。可实现远程控制设备，实时查询水厂运行情况，远程修改参数、设备巡检等。主要功能模块有无人泵站、智慧水厂、加药系统、视频监控、能耗管理、设备管理、水质监测，漏损管理、GIS管网、巡检系统、消息中心等。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="dzhkf"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-big align-lt" style="padding-top: 8%"><h4>定制化开发</h4></div>
              <div class="txt-box dzh-box">
                <p class="em">定制的不仅仅是软件和硬件 更是一种理念</p>
                <p>定制化开发是解决企业个性化难题唯一途径，而且是企业快速发展和提高竞争力的最好方法。</p>
                <el-button type="primary" plain class="zdh-btn" @click="handleShow">立即咨询</el-button>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic dzh-pic"></div>
          </el-col>
        </el-row>
        <div class="fz-sm align-ct" style="padding-top: 4%"><h5>定制化特点</h5></div>
        <div class="customized-box">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-gps"></i></div>
            <p>针对性强</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-bianjie"></i></div>
            <p>操作便捷</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-price"></i></div>
            <p>性价比高</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-service"></i></div>
            <p>服务周到</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-upgrade"></i></div>
            <p>升级方便</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop ref="backTop"></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧水务-产品中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧水务-产品中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {
    handleShow () {
      this.$refs.backTop.handleOpen()
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.product-water {
  .banner-water {
    background: url("../../assets/img/product/water/banner.png") no-repeat center;
    background-size: cover;
    position: relative;

    .txt {
      width: 20%;
      height: 20%;
      background: url("../../assets/img/product/water/txt.png") no-repeat center;
      background-size: contain;
      position: absolute;
      left: 26%;
      top: 30%;
    }
  }

  .bs-info {
    margin-top: 20%;

    .fz-big {
      padding-bottom: 10%;
    }
  }

  .a-pic {
    background-image: url("../../assets/img/product/water/case_a.png");
  }

  .b-pic {
    background-image: url("../../assets/img/product/water/case_b.png");
  }

  .c-pic {
    background-image: url("../../assets/img/product/water/case_c.png");
  }

  .d-pic {
    background-image: url("../../assets/img/product/water/case_d.png");
  }

  .e-pic {
    background-image: url("../../assets/img/product/water/case_e.png");
  }

  .f-pic {
    background-image: url("../../assets/img/product/water/case_f.png");
  }

  .g-pic {
    background-image: url("../../assets/img/product/water/case_g.png");
  }

  .h-pic {
    background-image: url("../../assets/img/product/water/case_h.png");
  }

  .i-pic {
    background-image: url("../../assets/img/product/water/case_i.png");
  }
}

</style>
